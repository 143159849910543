<script>
import HeaderBlock from "../blocks/HeaderBlock.vue";
import NewHomeMainBlock from "../blocks/NewHomeMainBlock.vue";
import NewPartnersBlock from "../blocks/NewPartnersBlock.vue";
import NewThemesItems from "../blocks/NewThemesItems.vue";
import HomeAboutBlock from "../blocks/HomeAboutBlock.vue";
import HomeVideoBlock from "../blocks/HomeVideoBlock.vue";
import homeTestBlock from "../blocks/homeTestBlock.vue";
import HomeBaseBlock from "../blocks/HomeBaseBlock.vue";
import PromoTheme from "../blocks/PromoTheme.vue";
import homeNewsBlock from "../blocks/homeNewsBlock.vue";
import SubscribeBlock from "../blocks/SubscribeBlock.vue";
import FooterBlock from "../blocks/FooterBlock.vue";
export default {
  name: "HomePage",
  components: {
    HeaderBlock,
    NewHomeMainBlock,
    NewPartnersBlock,
    NewThemesItems,
    HomeAboutBlock,
    HomeVideoBlock,
    homeTestBlock,
    HomeBaseBlock,
    PromoTheme,
    homeNewsBlock,
    SubscribeBlock,
    FooterBlock,
  },
};
</script>
<template>
  <HeaderBlock />
  <NewHomeMainBlock />
  <NewPartnersBlock />
  <NewThemesItems />
  <HomeAboutBlock />
  <HomeVideoBlock />
  <homeTestBlock />
  <HomeBaseBlock />
  <PromoTheme />
  <homeNewsBlock />
  <SubscribeBlock />
  <FooterBlock />
</template>